import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardContent,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import {Link as RouterLink, useParams, useNavigate, Link, useSearchParams} from 'react-router-dom';
import { useGet} from '../../API/request';
import '../../styles/All.css'
import PerfectScrollbar from 'react-perfect-scrollbar';
import {BallTriangle} from "react-loader-spinner";

import moment from "moment";

const CataloguesInfo = () => {

    const navigate = useNavigate();
    const getU = useGet();
    const {id} = useParams();

    const [isLoaded, setIsLoaded] = useState(true);

    const [catalogue, setCatalogue] = useState({});

    const getSpanForStatus = (status) => {
        if(status === 0) {
            return <span style={{
            }}>Новое</span>
        }
        if(status === 1) {
            return <span style={{
                color: "red"
            }}>Отклонена</span>
        }
        if(status === 2) {
            return <span style={{
                color: "blue"
            }}>В работе</span>
        }
        if(status === 3) {
            return <span style={{
                color: "green"
            }}>Выполнена</span>
        }
        if(status === 4) {
            return <span style={{
                color: "gray"
            }}>Устарела</span>
        }
       }

    const loadUsers = () => {
        setIsLoaded(true)

        let endpoint = `catalog/records/${id}`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setCatalogue(resp.data)
              

                }
            })
            .catch((err) => {
                setCatalogue([]);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };



    useEffect(() => {
        loadUsers();
    }, []);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Запись каталога</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate("/app/catalogues")}>
                        Назад
                    </Button>
                    Запись каталога
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Каталог</li>
                    <li>/</li>
                    <li>Информация</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', pt: 3, pb: 1}}>
                <Container maxWidth={false}>
                    <Card>
                        <CardContent sx={{p: 3}}>
                            <PerfectScrollbar>
                                <div className="wrapAvatar">
                                    <div className="info-block">
                                        <div className="wrap">
                                            <div className="label">
                                                Запись №:
                                            </div>
                                            <div className="text">
                                                {catalogue?.id || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Ссылка на каталог:
                                            </div>
                                            <div className="text">
                                            <a href={`/app/catalogues/edit/${catalogue?.catalog_id}`}>Каталог № {catalogue?.catalog_id}</a>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Название: 
                                            </div>
                                            <div className="text">
                                                {catalogue?.name || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                2GIS: 
                                            </div>
                                            <div className="text">
                                            <a href={catalogue?.['2gis']}>{catalogue?.['2gis'] || '---'} </a>

                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Индекс: 
                                            </div>
                                            <div className="text">
                                                {catalogue?.mail_index || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Телефоны:
                                            </div>
                                            <div className="text">
        {catalogue?.phones ? (
          JSON.parse(catalogue.phones).map((p, index) => <span key={index}>{p} </span>)
        ) : (
          '---'
        )}
      </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Почта:
                                            </div>
                                            <div className="text">
                                                {catalogue?.email || '---'}
                                            </div>
                                        </div>


                                        <div className="wrap">
                                            <div className="label">
                                                Сайты:
                                            </div>
                             
                                            <div className="text">
  {catalogue?.sites ? (
    JSON.parse(catalogue.sites).map((site, index) => (
      <a href={site} key={index} target="_blank" rel="noopener noreferrer">
        {site}
      </a>
    ))
  ) : (
    '---'
  )}
</div>
                                        </div>
                                        <div className="wrap">
        <div className="label">
          Twitter:
        </div>
        <div className="text">
          {catalogue?.twitter ? (
            <a href={catalogue.twitter} target="_blank" rel="noopener noreferrer">
              {catalogue.twitter}
            </a>
          ) : (
            '---'
          )}
        </div>
      </div>

      <div className="wrap">
        <div className="label">
          VK:
        </div>
        <div className="text">
          {catalogue?.vk ? (
            <a href={catalogue.vk} target="_blank" rel="noopener noreferrer">
              {catalogue.vk}
            </a>
          ) : (
            '---'
          )}
        </div>
      </div>

      <div className="wrap">
        <div className="label">
          WhatsApp:
        </div>
        <div className="text">
          {catalogue?.WhatsApp && JSON.parse(catalogue.WhatsApp).length ? (
            JSON.parse(catalogue.WhatsApp).map((link, index) => (
              <a href={link} key={index} target="_blank" rel="noopener noreferrer">
                {link}
              </a>
            ))
          ) : (
            '---'
          )}
        </div>
      </div>

      <div className="wrap">
        <div className="label">
          Viber:
        </div>
        <div className="text">
          {catalogue?.Viber && JSON.parse(catalogue.Viber).length ? (
            JSON.parse(catalogue.Viber).map((link, index) => (
              <a href={link} key={index} target="_blank" rel="noopener noreferrer">
                {link}
              </a>
            ))
          ) : (
            '---'
          )}
        </div>
      </div>

      <div className="wrap">
        <div className="label">
          Telegram:
        </div>
        <div className="text">
          {catalogue?.Telegram && JSON.parse(catalogue.Telegram).length ? (
            JSON.parse(catalogue.Telegram).map((link, index) => (
              <a href={link} key={index} target="_blank" rel="noopener noreferrer">
                {link}
              </a>
            ))
          ) : (
            '---'
          )}
        </div>
      </div>

      <div className="wrap">
        <div className="label">
          Youtube:
        </div>
        <div className="text">
          {catalogue?.Youtube ? (
            <a href={catalogue.Youtube} target="_blank" rel="noopener noreferrer">
              {catalogue.Youtube}
            </a>
          ) : (
            '---'
          )}
        </div>
      </div>

      <div className="wrap">
        <div className="label">
          Skype:
        </div>
        <div className="text">
          {catalogue?.Skype ? (
            <a href={catalogue.Skype} target="_blank" rel="noopener noreferrer">
              {catalogue.Skype}
            </a>
          ) : (
            '---'
          )}
        </div>
      </div>
      <div className="wrap">
                                            <div className="label">
                                                Новое:
                                            </div>
                                            <div className="text">
                                        
                                                {catalogue?.freshness ? <span style={{
            color: 'green'
                }}>Да</span> : <span>Нет</span>}
                                            </div>
                                        </div>

                            
                                        <div className="wrap">
                                            <div className="label">
                                                Статус
                                            </div>
                                            <div className="text">
                                            {getSpanForStatus(catalogue?.status)}
                                            </div>
                                        </div>
                                 
                                        <div className="wrap">
                                            <div className="label">
                                                Дата создания:
                                            </div>
                                            <div className="text">
                                                {moment(catalogue?.date_create).format('HH:mm, DD.MM.YYYY') || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Дата обновления:
                                            </div>
                                            <div className="text">
                                                { catalogue?.date_update ? moment(catalogue?.date_update).format('HH:mm, DD.MM.YYYY') : '---'}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </PerfectScrollbar>
                        </CardContent>
                    </Card>
                </Container>
            </Box>

        </>
    );
};

export default CataloguesInfo;
