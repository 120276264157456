import React, { useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, Card, CardHeader, Divider, CardContent, TextField, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import { usePost} from "../../API/request";
import '../../styles/Avatar/style.css'

const CataloguesAdd = () => {
    const navigate = useNavigate();
    const postU = usePost();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [values, setValues] = useState({
        name: '',

    });
    const [errors, setErrors] = useState({
        name: false,

    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        if(!values.name) {
            
        }
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const validateInfo = () => {
        let validComplete = true;
        if (values.name.trim() === '') {
            validComplete = false;
            errors.name = true
            showAlert('error', "Поле Название не должно быть пустым")

        }
        return validComplete;
    };



    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const submitInfo = async () => {
       

            if(validateInfo()) {
                const data = {
                    name: values.name,
                };
                postU('catalog', data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Данные успешно добавлены');
                        navigate(`/app/catalogues/edit/${resp.data.id}`)
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
            }

    
        
    };






    return (
        <>
            <Helmet>
                <title>Добавление каталога</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Каталоги
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Каталоги</li>
                    <li>/</li>
                    <li>Добавление</li>
                </ul>

            </Box>

            <Box sx={{backgroundColor: 'background.default', pt: 3}}>

                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title={'Добавление записи каталога'}
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />               
                                </CardContent>
                                <Divider/>
                                <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                    {alert.txt}
                                </Alert>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitInfo}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>

        </>
    );
};

export default CataloguesAdd;
