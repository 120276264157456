import React from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { format } from 'date-fns';

const WeekPicker = ({ date, setDate }) => {
  const handleSelect = (range) => {
    if (range && range.length === 2) {
      setDate({
        start: format(range[0], 'yyyy-MM-dd'),
        end: format(range[1], 'yyyy-MM-dd')
      });
    }
  };

  return (
    <div style={{
        marginTop: "10px",
        marginBottom: "10px"
    }}>
      <DateRangePicker
        onChange={handleSelect}
        ranges={[]}
        value={[new Date(date.start), new Date(date.end)]}
        format="yyyy-MM-dd"
      />
    </div>
  );
};

export default WeekPicker;
