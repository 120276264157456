import {Navigate} from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Logs from "./pages/Systems/Logs/Logs";
import Dashboard from "./pages/Dashboard/Dashboard";
import EmailTemplateList from "./pages/Templates/Email-Templates/EmailTemplateList";
import EmailTemplateCreate from "./pages/Templates/Email-Templates/EmailTemplateCreate";
import EmailTemplateEdit from "./pages/Templates/Email-Templates/EmailTemplateEdit";
import CommonSettings from "./pages/Systems/CommonSettings/CommonSettings";
import UpdateSystem from "./pages/Systems/UpdateSystem/UpdateSystem";
import SettingsExampl from "./pages/Settings/SettingsExampl";
import UserAdd from "./pages/User/UserAdd";
import EmailHistoryList from "./pages/Templates/Email-Hstory/EmailHistoryList";
import ManagerList from "./pages/User/ManagerList";
import ThemeList from "./pages/Theme/ThemeList";
import ThemesEdit from "./pages/Theme/ThemesEdit";
import ThemesAdd from "./pages/Theme/ThemesAdd";
import PlatformList from "./pages/Platforms/PlatformList";
import PlatformEdit from "./pages/Platforms/PlatformEdit";
import PlatformsAdd from "./pages/Platforms/PlatformsAdd";
import ApplicationsInfo from "./pages/Applications/ApplicationsInfo";
import ApplicationEdit from "./pages/Applications/ApplicationEdit";
import ApplicationList from "./pages/Applications/ApplicationList";
import ApplicationPayment from "./pages/Applications/ApplicationPayment";
import PaymentEdit from "./pages/Payment/PaymentEdit";
import PaymentList from "./pages/Payment/PaymentList";
import Info from "./pages/Info/Info";
import CataloguesInfo from './pages/Catalogues/CataloguesInfo';
import CataloguesEdit from './pages/Catalogues/CataloguesEdit';
import CataloguesList from './pages/Catalogues/CataloguesList';
import CataloguesAdd from './pages/Catalogues/CataloguesAdd';


const routes = [
    {
        path: 'app',
        element: <DashboardLayout/>,
        children: [
            {path: 'account', element: <Account/>},
            {path: 'dashboard', element: <Dashboard/>},

            {path: 'user/:id', element: <UserInfo/>},
            {path: 'user/edit/:id', element: <UserEdit/>},
            {path: 'user/add', element: <UserAdd/>},
            {path: 'users', element: <UserList/>},
            {path: 'managers', element: <ManagerList/>},

            {path: 'applications/:id', element: <ApplicationsInfo/>},
            {path: 'applications/edit/:id', element: <ApplicationEdit/>},
            {path: 'applications', element: <ApplicationList/>},
            {path: 'applications/payment/:id', element: <ApplicationPayment/>},


            {path: 'catalogues/:id', element: <CataloguesInfo/>},
            {path: 'catalogues/add', element: <CataloguesAdd/>},
            {path: 'catalogues/edit/:id', element: <CataloguesEdit/>},
            {path: 'catalogues', element: <CataloguesList/>},
            
            {path: 'applications/payment/:id', element: <ApplicationPayment/>},

            {path: 'payment/edit/:id', element: <PaymentEdit/>},
            {path: 'payment', element: <PaymentList/>},

            {path: 'themes', element: <ThemeList/>},
            {path: 'themes/edit/:id', element: <ThemesEdit/>},
            {path: 'themes/add', element: <ThemesAdd/>},

            {path: 'platforms', element: <PlatformList/>},
            {path: 'platforms/edit/:id', element: <PlatformEdit/>},
            {path: 'platforms/add', element: <PlatformsAdd/>},

            { path: 'info', element: <Info/> },

            { path: 'email-templates', element: <EmailTemplateList /> },
            { path: 'email-history', element: <EmailHistoryList /> },
            { path: 'email-templates/create', element: <EmailTemplateCreate /> },
            { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },

            {path: 'logs', element: <Logs/>},
            {path: 'common-settings', element: <CommonSettings/>},
            {path: 'update-system', element: <UpdateSystem/>},


            {path: 'settings', element: <SettingsExampl/>},


            {path: '', element: <Navigate to="/app/users"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {path: 'login', element: <Login/>},
            {path: '404', element: <NotFound/>},
            {path: '/', element: <Navigate to="/app/users"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    }
];

export default routes;
