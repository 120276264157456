import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button, Card, CardHeader, Divider, CardContent, CircularProgress, TextField, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import React, {useState} from "react";
import {usePost} from "../../API/request";

const ThemesAdd = () => {

    const navigate = useNavigate();
    const postU = usePost();

    const [values, setValues] = useState({
        key: '',
        name: '',
    });

    const [errors, setErrors] = useState({
        key: '',
        name: '',
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };



    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name.trim() === '') {
            validComplete = false;
            formErrors.firstName = true;
        }

        setErrors(formErrors);
        return validComplete;
    };



    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            const data = {
                key: values.name,
                name: values.name
            }

            postU('admin/themes', data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно добавили тему');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    showAlert('error', err.response.data.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Create theme</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Темы
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Темы</li>
                    <li>/</li>
                    <li>Добавление</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '85%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Создание новой темы"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>

                                    <TextField
                                        fullWidth
                                        label="Название темы"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Создать
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ThemesAdd;
