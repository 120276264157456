import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardContent,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import {Link as RouterLink, useParams, useNavigate, Link, useSearchParams} from 'react-router-dom';
import { useGet} from '../../API/request';
import '../../styles/All.css'
import PerfectScrollbar from 'react-perfect-scrollbar';
import {BallTriangle} from "react-loader-spinner";

import moment from "moment";

const ApplicationsInfo = () => {

    const navigate = useNavigate();
    const getU = useGet();
    const {id} = useParams();

    const [isLoaded, setIsLoaded] = useState(true);

    const [application, setApplication] = useState({});
    const [documents, setDocuments] = useState([]);

    const loadUsers = () => {
        setIsLoaded(true)

        let endpoint = `applications/${id}`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {

                    setApplication(resp.data.application)
                    setDocuments(resp.data.documents)

                }
            })
            .catch((err) => {
                setApplication([]);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };



    useEffect(() => {
        loadUsers();
    }, []);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Заявка</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate("/app/applications")}>
                        Назад
                    </Button>
                    Заявки
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Заявки</li>
                    <li>/</li>
                    <li>Информация</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', pt: 3, pb: 1}}>
                <Container maxWidth={false}>
                    <Card>
                        <CardContent sx={{p: 3}}>
                            <PerfectScrollbar>
                                <div className="wrapAvatar">
                                    <div className="info-block">
                                        <div className="wrap">
                                            <div className="label">
                                                Заявка №:
                                            </div>
                                            <div className="text">
                                                {application?.id || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Сообщение
                                            </div>
                                            <div className="text">
                                                {application?.message || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Ссылка
                                            </div>
                                            <div className="text">
                                                {application?.link || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Платформа
                                            </div>
                                            <div className="text">
                                                {application?.platform || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Тема:
                                            </div>
                                            <div className="text">
                                                {application?.theme || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Стоимость:
                                            </div>
                                            <div className="text">
                                                {application?.cost || '---'}
                                            </div>
                                        </div>


                                        <div className="wrap">
                                            <div className="label">
                                                Время выполнения (дни):
                                            </div>
                                            <div className="text">
                                                {application?.leadTime || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Дата создания:
                                            </div>
                                            <div className="text">
                                                {moment(application?.creationDate).format('HH:mm, DD.MM.YYYY') || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Статус
                                            </div>
                                            <div className="text">
                                                {application?.status || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Комментрий:
                                            </div>
                                            <div className="text">
                                                {application?.comment || '---'}
                                            </div>
                                        </div>


                                        <div className="wrap">
                                            <div className="label">
                                                Оплачено:
                                            </div>
                                            <div className="text">
                                                {application?.payment || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Номер телефона:
                                            </div>
                                            <div className="text">
                                                {application?.phone || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Почта:
                                            </div>
                                            <div className="text">
                                                {application?.email || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Другие контакты:
                                            </div>
                                            <div className="text">
                                                {application?.anotherContact || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Почта создателя заявки:
                                            </div>
                                            <div className="text">
                                                {application?.performer?.email || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Доп. файлы:
                                            </div>
                                            <ul className="text">
                                                {documents.length === 0 ? '--' :<>
                                                {documents?.map(doc => <li><a href={`${process.env.REACT_APP_API_URL}/${doc.name}`} target="_blank">{doc.name}</a></li>)}</>}
                                                  </ul>

                                            
                                        </div>
                                    </div>

                                </div>
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Link to={`/app/applications/payment/${application.id}`}>
                                        <Button color="primary"
                                                variant="contained">
                                            Оплата
                                        </Button>
                                    </Link>
                                </div>
                            </PerfectScrollbar>
                        </CardContent>
                    </Card>
                </Container>
            </Box>

        </>
    );
};

export default ApplicationsInfo;
