import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider,
    Checkbox,
    FormControlLabel,
    FormGroup
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useDelete, useGet} from '../../API/request';

import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css'
import { useConfirm } from '../../components/Confirm';

const CataloguesList = () => {
    const getU = useGet();
    const deleteU = useDelete()
    const [searchParams, setSearchParams] = useSearchParams();
    const confirm = useConfirm();
    const [isLoaded, setIsLoaded] = useState(true);
    const [catalogues, setCatalogues] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
   

    const [queryParams, setQueryParams] = useState({
        id: searchParams.get("id") || '',
        name: searchParams.get("name") || '',
    });

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        let params = {}

        if (queryParams.id !== '') {
            params.id = queryParams.id
        }

        if (queryParams.name !== '') {
            params.name = queryParams.name
        }

        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }

    const handleDeleteRecord = (id) => {
        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить данную запись?',
            onConfirm: () => {
                deleteU(`catalog/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadRecords();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', e.response.data.message.message)
                    });
            }
        });
    }

    const loadRecords = () => {
        setIsLoaded(true)
        let endpoint = `catalog?page=${page + 1}&limit=${limit}`;
        if (queryParams.id !== '') {
            endpoint += `&id=${searchParams.get("id")}`;
        }
        if (queryParams.name !== '') {
            endpoint += `&name=${searchParams.get("name")}`;
        }
        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setCatalogues(resp.data.records);
                    setCount(resp.data.totalCount || 0);
                }
            })
            .catch((err) => {
                setCatalogues([]);
                setCount(0);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    useEffect(() => {
        if (queryParams.id === '') {
            searchParams.delete("id")
            setSearchParams(searchParams);
        }
        if (queryParams.name === '') {
            searchParams.delete("name")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        loadRecords();
    }, [page, limit, searchParams]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Каталоги</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Каталоги
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 1200}}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mx: 2,
                                            mb: 1
                                        }}>
                                            <Box 
                                            sx={{
                                                minWidth: '300px',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                mx: 2,
                                                mb: 1
                                            }}
                                            >       <TextField
                                                fullWidth
                                                label="Номер каталога"
                                                margin="normal"
                                                name="id"
                                                onChange={handleChangeQueryParams}
                                                type="number"
                                                value={queryParams.id}
                                                variant="outlined"
                                                style={{width: '90%', marginRight: 3}}
                                            />
<TextField
                                                fullWidth
                                                label="Название каталога"
                                                margin="normal"
                                                name="name"
                                                onChange={handleChangeQueryParams}
                                                type="text"
                                                value={queryParams.name}
                                                variant="outlined"
                                                style={{width: '90%', marginInline: 5}}
                                            />
                                            <Button
                                                color="warning"
                                                variant="contained"
                                                onClick={handleFilterQueryParams}
                                                sx={{mt: 2, mb: 1}}
                                            >
                                                Поиск
                                            </Button>
                                            
                                            </Box>
                                            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <Box sx={{marginLeft: 2}}>
                                <Link to="/app/catalogues/add">
                                    <Button color="primary" variant="contained">
                                        Добавить каталог
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                                        </Box>
                                        <Divider/>
                                        
                                        <Table>
                                        <TableHead>
    <TableRow>
        <TableCell>
            № Каталога
        </TableCell>
        <TableCell>
            Название
        </TableCell>
        <TableCell>
         
        </TableCell>
    </TableRow>
</TableHead>
<TableBody>
    {catalogues?.map((item) => (
        <TableRow hover key={item.id} sx={{ width: '100%' }}>
            <TableCell sx={{ width: 80 }}>
                {item?.id}
            </TableCell>
            <TableCell>
                {item?.name || '---'}
            </TableCell>
            <TableCell sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex' }}>
           
                    <Box sx={{ ml: 2 }}>
                        <Link to={item?.role === "admin" || item.status !== 'Оплата' && `/app/catalogues/edit/${item.id}`}>
                            <Button
                                color="success"
                                variant="contained"
                                disabled={item?.role === "admin" || item.status === 'Оплата'}
                            >
                              Инфо. и ред.
                            </Button>
                        </Link>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <Button
                            color="error"
                            variant="contained"
                            onClick={() => handleDeleteRecord(item.id)}
                        >
                            Удалить
                        </Button>
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
    ))}
</TableBody>

                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        labelRowsPerPage={
                                                            <span>Кол-во строк на странице:</span>}
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default CataloguesList;
