import React, { useState, useEffect } from "react";

import {
  Box,
  Container,
  Button,
  Card,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

import { useDelete, useGet } from "../../API/request";
import "../../styles/All.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BallTriangle } from "react-loader-spinner";
import UserListSkelet from "../../skeletons/UserListSkelet";
import { useConfirm } from "../../components/Confirm";
import { useParams } from "react-router";


const UserInfoBonusesTable = () => {
  const getU = useGet();
  const deleteU = useDelete()
  const confirm = useConfirm()
  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [bonuses, setBonuses] = useState([]);



  const { id } = useParams();

  const deleteBonusInfo = (id) => {
    confirm({
        title: 'Удаление',
        content: 'Вы уверены, что хотите удалить данную запись?',
        onConfirm: () => {
            deleteU(`users/bonuses/${id}`)
                .then((resp) => {
                    if (resp.status === 'success') {
                 
                        loadBonuses();
                    }
                })
                .catch((e) => {
                    showAlert('error', e.response.data.message.message)
                });
        }
    });
}

  const loadBonuses = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `users/bonuses/${id}?page=${
      page + 1
    }&limit=${limit}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === "success") {
      setBonuses(resp.data.bonuses.data)
      setCount(resp.data.currentCount || 0);
      setIsDataLoading(false);
      setIsLoaded(false)
  
        }
      })
      .catch((err) => {
        setBonuses([]);
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };



  useEffect(() => {
    loadBonuses();
  }, [page, limit]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
 <>      <Box>
 <Box className="headerWrapper">
   <Box className="headerTitle">Бонусы</Box>
 </Box>
 <Box sx={{ backgroundColor: "background.default", minHeight: "100%" }}>
   <Container maxWidth={false}>
     {isDataLoading ? (
       <UserListSkelet />
     ) : (
       <>
         <Box sx={{ pt: 3 }}>
           <Card>
             <PerfectScrollbar>
               <Box sx={{ minWidth: 900 }}>
                 <Divider />
                 <Table>
                   <TableHead>
                     <TableRow>
                       <TableCell sx={{ width: 80 }}>
                       Комментарий
                       </TableCell>
                       <TableCell>Сколько к оплате</TableCell>
                       <TableCell>Дата создания</TableCell>
                      
                       <TableCell>

</TableCell>
                     </TableRow>
                   </TableHead>

                   <TableBody>
                     {bonuses?.map((bonus) => (
                       <TableRow hover key={bonus.id}>
                         <TableCell sx={{ width: 80 }}>
                           {bonus.description}
                         </TableCell>
                         <TableCell>{bonus.cost}</TableCell>
                         <TableCell>
                         
                             {bonus?.creationDate}
                         </TableCell>

                         <TableCell sx={{width: 100}}>
                                                     <Button
                                                         color="error"
                                                         variant="contained"
                                                         onClick={() => deleteBonusInfo(bonus.id)}
                                                     >
                                                         Удалить
                                                     </Button>
                                                 </TableCell>
                       </TableRow>
                     ))}
                   </TableBody>
                   <TableFooter>
                     <TableRow>
                       <TablePagination
                         labelRowsPerPage={
                           <span>Кол-во строк на странице:</span>
                         }
                         rowsPerPageOptions={[10, 20, 30, 40, 50]}
                         colSpan={7}
                         count={count}
                         rowsPerPage={limit}
                         page={page}
                         onPageChange={handleChangePage}
                         onRowsPerPageChange={handleChangeLimit}
                       />
                     </TableRow>
                   </TableFooter>
                 </Table>
               </Box>
             </PerfectScrollbar>
           </Card>
         </Box>
       </>
     )}
   </Container>
 </Box>
</Box></>
  );
};

export default UserInfoBonusesTable;
