import { Box, Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useGet } from "../../API/request";
import { BallTriangle } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { endOfMonth, format, startOfMonth } from "date-fns";
import MonthPicker from "./MonthPicker"; 
import { getMonthName } from "../../utils";


const ManagerDataTable = () => {
  const getCurrentMonthRange = () => {
    const now = new Date();
    const start = startOfMonth(now);
    const end = endOfMonth(now);

    const startFormatted = format(start, 'yyyy-MM-dd');
    const endFormatted = format(end, 'yyyy-MM-dd');

    return {
      start: startFormatted,
      end: endFormatted
    };
  };

  const currentMonth = getCurrentMonthRange();
  const [loaded, setLoaded] = useState(true);
  const [managerData, setManagerData] = useState([]);
  const [date, setDate] = useState({
    start: currentMonth.start,
    end: currentMonth.end
  });

  const getU = useGet();

  const fetchManagerData = async () => {
    try {
      const resp = await getU(`stats/managers?date=${date.start}`);
      if (resp.status === 'success') {
        setManagerData(resp.data.stats);
      }
    } catch (e) {
      showAlert('error', e.response.data.message);
    } finally {
      setLoaded(false);
    }
  };


  useEffect(() => {
    setLoaded(true);
    fetchManagerData();
  }, [date]);

  if (loaded) {
    return (
      <div className="loader">
        <BallTriangle height="100" width="100" color="grey" ariaLabel="loading" />
      </div>
    );
  }

  return (
    <Box sx={{ backgroundColor: "background.default", py: 3 }}>
      <Card>
        <CardContent>
          <MonthPicker date={date} setDate={setDate} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 80 }}>Менеджер</TableCell>
                <TableCell>Заявки (с/в)</TableCell>
                <TableCell>Лиды</TableCell>
                <TableCell>Клиенты</TableCell>
                <TableCell sx={{ backgroundColor: "rgba(235, 235, 242, 0.5)" }}>Заявки (мес.)</TableCell>
                <TableCell sx={{ backgroundColor: "rgba(235, 235, 242, 0.5)"  }}>Время (мес.)</TableCell>
                <TableCell sx={{ backgroundColor: "rgba(235, 235, 242, 0.5)"  }}>KPI</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {managerData.map((data) => (
                <TableRow hover key={data.manager.id}>
                  <TableCell sx={{ width: 80 }}>
                    <Link to={`/app/user/${data.manager.id}`} target="_blank">
                      {data.manager?.username}
                    </Link>
                  </TableCell>
                  <TableCell>{data.today}/{data.yesterday}</TableCell>
                  <TableCell>{data.leads}</TableCell>
                  <TableCell>{data.clients}</TableCell>
                  <TableCell sx={{ backgroundColor: "rgba(235, 235, 242, 0.5)"  }}>{data.last30Days}</TableCell>
                  <TableCell sx={{ backgroundColor: "rgba(235, 235, 242, 0.5)"  }}>{data.hoursLast30Days}</TableCell>
                  <TableCell sx={{ backgroundColor: "rgba(235, 235, 242, 0.5)" }}>{data.kpi}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
     {/*Оставила сам компонент (на случай, если потом понадобится. Это график по каждому из менеджеров*/}
      {/* {managerData.map(data => <ManagerLineChart managerId={data.manager.id} name={data.manager.username} />)} */}
    </Box>
  );
};

export default ManagerDataTable;
