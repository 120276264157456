import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, Card, CardHeader, Divider, CardContent, CircularProgress, TextField, Alert, Grid
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import UserFormEdit from '../../components/Users/UserFormEdit';
import {useGet, usePut} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import '../../styles/Avatar/style.css'

const ApplicationEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut();

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);
const [documents, setDocuments] = useState([])
    const [values, setValues] = useState({
        message: '',
        link: '',
        platform: '',
        theme: '',
        cost: '',
        leadTime: '',
        status: '',
        comment: '',
        payment: '',
        phone: '',
        email: '',
        anotherContact: ''
    });
    const [errors, setErrors] = useState({
        message: false,
        link: false,
        platform: false,
        theme: false,
        cost: false,
        leadTime: false,
        status: false,
        comment: false,
        payment: false,
        phone: false,
        email: false,
        anotherContact: false
    });

    const [statusList, setStatusList]= useState([])
    const [themeList, setThemeList]= useState([])
    const [platformList, setPlatformList]= useState([])


    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validateInfo = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.role === '') {
            validComplete = false;
            formErrors.role = false;
            showAlert('error', "Поле Role не должно быть пустым")
        }

        setErrors(formErrors);
        return validComplete;
    };



    const submitInfo = async () => {
        if (nothingChanged) {
            showAlert('error', 'Нет изменений');
            return;
        }

        if (validateInfo()) {
            setSubmitDisabled(true);

            const data = {
                message: values.message,
                link: values.link,
                platform: values.platform,
                theme: values.theme,
                cost: values.cost,
                leadTime: values.leadTime,
                status: values.status,
                comment: values.comment,
                phone: values.phone,
                email: values.email,
                anotherContact: values.anotherContact
            };

            putU(`applications/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Данные успешно обновленны');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };

    const getDataLists = async () =>{
        await setIsLoaded(true)

        await getU(`applications/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setValues(resp.data.application)
                }
            })
            .catch((e) => {
                showAlert('error', e.response.data.message);
            })
            .finally(() => {

            });

        await getU(`platforms`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setPlatformList(resp.data.platforms)
                }
            })
            .catch((e) => {
                showAlert('error', e.response.data.message);
            })
            .finally(() => {

            });

        await getU(`themes`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setThemeList(resp.data.themes)
                }
            })
            .catch((e) => {
                showAlert('error', e.response.data.message);
            })
            .finally(() => {

            });

        await getU(`admin/applications/statuses`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setStatusList(resp.data.statuses)
                }
            })
            .catch((e) => {
                showAlert('error', e.response.data.message);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }

    useEffect(() => {
        getDataLists();
    }, []);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit application</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Заявки
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Заявки</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>

            </Box>

            <Box sx={{backgroundColor: 'background.default', pt: 3}}>

                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title={`Редактирование заявки №${id}`}
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="Сообщение"
                                        margin="normal"
                                        name="message"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.message}
                                        variant="outlined"
                                        error={errors.message}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Ссылка"
                                        margin="normal"
                                        name="link"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.link}
                                        variant="outlined"
                                        error={errors.link}
                                    />
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="platform">
                                            Платформа
                                        </InputLabel>
                                        <Select
                                            labelId="platform"
                                            name="platform"
                                            value={values.platform}
                                            label="Платформа"
                                            onChange={handleChange}
                                        >
                                            {
                                                platformList?.map((item) => <MenuItem value={item.name}>{item.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="theme">
                                            Тема
                                        </InputLabel>
                                        <Select
                                            labelId="theme"
                                            name="theme"
                                            value={values.theme}
                                            label="Тема"
                                            onChange={handleChange}
                                        >
                                            {
                                                themeList?.map((item) => <MenuItem value={item.name}>{item.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        label="Стоимость"
                                        margin="normal"
                                        name="cost"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.cost}
                                        variant="outlined"
                                        error={errors.cost}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Время выполнения (дни)"
                                        margin="normal"
                                        name="leadTime"
                                        onChange={handleChange}
                                        type="leadTime"
                                        value={values.leadTime}
                                        variant="outlined"
                                        error={errors.leadTime}
                                    />
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="status">
                                            Статус
                                        </InputLabel>
                                        <Select
                                            labelId="status"
                                            name="status"
                                            value={values.status}
                                            label="Статус"
                                            onChange={handleChange}
                                        >
                                            {
                                                statusList?.map((item) => <MenuItem value={item.name}>{item.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        label="Комментарий"
                                        margin="normal"
                                        name="comment"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.comment}
                                        variant="outlined"
                                        error={errors.comment}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Номер телефона"
                                        margin="normal"
                                        name="phone"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.phone}
                                        variant="outlined"
                                        error={errors.phone}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        margin="normal"
                                        name="email"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.email}
                                        variant="outlined"
                                        error={errors.email}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Другие контакты"
                                        margin="normal"
                                        name="anotherContact"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.anotherContact}
                                        variant="outlined"
                                        error={errors.anotherContact}
                                    />
                                </CardContent>
                                <Divider/>
                                <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                    {alert.txt}
                                </Alert>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitInfo}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>

        </>
    );
};

export default ApplicationEdit;
