import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  TextField,
  TableFooter,
  TablePagination,
  Alert,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDelete, useGet, usePost } from "../../API/request";
import "../../styles/All.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BallTriangle } from "react-loader-spinner";
import UserListSkelet from "../../skeletons/UserListSkelet";
import { useConfirm } from "../../components/Confirm";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UserInfoBonusesTable from "./UserInfoBonusesTable";



const UserInfo = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const deleteU = useDelete()
  const { id } = useParams();
  const confirm = useConfirm()
  const postU = usePost();
  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [user, setUser] = useState({});
  const [cost, setCost] = useState('');
  const [description, setDescription] = useState('');
  const [bonusIsOpen, setBonusIsOpen] = useState(false);

  const [errors, setErrors] = useState({
    cost: false,
});

  const [statistics, setStatistics] = useState({
    hours: 0,
    pay: 0,
    applicationsCount: 0,
    notActiveApplicationsCount: 0,
    nonTargetApplicationsCount: 0,
    rejectedApplicationsCount: 0,
    contactApplicationsCount: 0,
    successApplicationsCount: 0,
    paidApplicationsCount: 0,
  });

  const [timeTracks, setTimeTracks] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);


  const [queryParams, setQueryParams] = useState({
    fromDate: searchParams.get("fromDate") || "",
    toDate: searchParams.get("toDate") || "",
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
});

  const showAlert = (type, text) => {
    setAlert({
        txt: text,
        type,
        isVisible: true
    });

    setTimeout(() => {
        setAlert({
            txt: text,
            type,
            isVisible: false
        });

        setSubmitDisabled(false);
    }, 2500);
};

  const handleSubmit = (event) => {
    event.preventDefault();
   if(cost === '' || 0) {
    setErrors({cost: true})
   } else {
    setErrors({cost: false})
   }

   let endpoint = `users/bonuses/${id}`
   postU(endpoint, {
    cost,
   description, 
}).then((resp) => {
        if (resp.status === 'success') {
            showAlert('success', 'Вы успешно добавили бонус');
       
        } else {
            showAlert('error', 'Ошибка');
        }
    })
    .catch((err) => {
            showAlert('error', 'Ошибка сервера');
        
    });
  }

  const deleteTrackingInfo = (id) => {
    confirm({
        title: 'Удаление',
        content: 'Вы уверены, что хотите удалить данную запись?',
        onConfirm: () => {
            deleteU(`timetracker/${id}`)
                .then((resp) => {
                    if (resp.status === 'success') {
                        loadUsers();
                        loadTimeTracks();
                    }
                })
                .catch((e) => {
                    showAlert('error', e.response.data.message.message)
                });
        }
    });
}
  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.fromDate !== "") {
      params.fromDate = dayjs(queryParams.fromDate).format("YYYY-MM-DD");
    }
    if (queryParams.toDate !== "") {
      params.toDate = dayjs(queryParams.toDate).format("YYYY-MM-DD");
    }

    if (Object.keys(params).length !== 0) {
      setSearchParams(params);
    }
  };


  const loadUsers = () => {
    setIsLoaded(true);

    let endpoint = `admin/statistics/${id}`;

    if (queryParams.fromDate !== "") {
      endpoint += `?fromDate=${searchParams.get("fromDate")}`;
    }
    if (queryParams.toDate !== "") {
      endpoint += `&toDate=${searchParams.get("toDate")}`;
    }

    

    getU(endpoint)
      .then((resp) => {
        if (resp.status === "success") {
          const statisticList = resp.data.statistics;

          setUser(resp.data.statistics.user);

          setQueryParams({
            fromDate: resp.data.fromDate,
            toDate: resp.data.toDate,
          });

          const params = {
            fromDate: resp.data.fromDate,
            toDate: resp.data.toDate,
          };
          setSearchParams(params);

          setStatistics({
            hours: statisticList.hours,
            pay: statisticList.pay,
            applicationsCount: statisticList.applicationsCount,
            notActiveApplicationsCount:
              statisticList.notActiveApplicationsCount,
            nonTargetApplicationsCount:
              statisticList.nonTargetApplicationsCount,
            rejectedApplicationsCount: statisticList.rejectedApplicationsCount,
            contactApplicationsCount: statisticList.contactApplicationsCount,
            successApplicationsCount: statisticList.successApplicationsCount,
            paidApplicationsCount: statisticList.paidApplicationsCount,
          });
        }
      })
      .catch((err) => {
        setUser([]);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const loadTimeTracks = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `admin/timetracker?performerId=${id}&page=${
      page + 1
    }&limit=${limit}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === "success") {
    
          setTimeTracks(resp.data.timeTracks);
          setCount(resp.data.currentCount || 0);
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        setTimeTracks([]);
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (queryParams.fromDate === "") {
      searchParams.delete("fromDate");
      setSearchParams(searchParams);
    }
    if (queryParams.toDate === "") {
      searchParams.delete("toDate");
      setSearchParams(searchParams);
    }
  }, [queryParams]);


  useEffect(() => {
    loadUsers();
  }, [searchParams]);

  useEffect(() => {
    loadTimeTracks();
  }, [page, limit]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>User</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button
            startIcon={<ChevronLeft />}
            onClick={() => navigate("/app/users")}
          >
            Назад
          </Button>
          Пользователи
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Пользователи</li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: "background.default", pt: 3, pb: 1 }}>
 
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="avatar-block">
                    <Avatar
                      src={`${process.env.REACT_APP_API_URL}/uploads/avatars/${user.avatar}`}
                      className="avatar"
                    />
                  </div>
                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">ID:</div>
                      <div className="text">{user?.id || "---"}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Login</div>
                      <div className="text">{user?.username || "---"}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Имя</div>
                      <div className="text">{user?.name || "---"}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Фамилия</div>
                      <div className="text">{user?.username || "---"}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Email:</div>
                      <div className="text">{user?.email || "---"}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Роль:</div>
                      <div className="text">{user?.role || "---"}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Ставка:</div>
                      <div className="text">{user?.perHour || "---"}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Позиция:</div>
                      <div className="text">
                        {user?.isSenior
                          ? "Старший менеджер"
                          : "Младший менеджер" || "---"}
                      </div>
                    </div>
                    <div className="wrap">
                      <div className="label">Зарплата за текущй месяц:</div>
                      <div className="text">{user?.thisMonthPay || "---"}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Контакт:</div>
                      <div className="text">{user?.contact || "---"}</div>
                    </div>
                  </div>


                </div>

              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
        
      </Box>


      <Box sx={{ backgroundColor: "background.default", py: 3 }}>

        <Container maxWidth={false}>
          <>
            <Box sx={{ pt: 3 }}>
              <Card>
                <PerfectScrollbar>
                  <Box sx={{ minWidth: 1000 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mx: 2,
                        mb: 1,
                        mt: 2,
                      }}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="ru"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box style={{ width: "45%" }}>
                            <DatePicker
                              onError={console.log}
                              minDate={dayjs("2020-01-01")}
                              maxDate={dayjs("2100-12-31")}
                              sx={{ width: "100%" }}
                              name="fromDate"
                              variant="static"
                              inputFormat="DD.MM.YYYY"
                              label="С какой даты"
                              renderInput={(props) => <TextField {...props} />}
                              value={queryParams.fromDate}
                              onChange={(newValue) => {
                                setQueryParams({
                                  ...queryParams,
                                  fromDate: newValue,
                                });
                              }}
                            />
                          </Box>
                          <Box style={{ width: "45%" }}>
                            <DatePicker
                              minDate={dayjs("2020-01-01")}
                              maxDate={dayjs("2100-12-31")}
                              sx={{ minWidth: 300 }}
                              ampm={false}
                              variant="static"
                              inputFormat="DD.MM.YYYY"
                              name="toDate"
                              label="По какую дату"
                              renderInput={(props) => <TextField {...props} />}
                              value={queryParams.toDate}
                              onChange={(newValue) => {
                                setQueryParams({
                                  ...queryParams,
                                  toDate: newValue,
                                });
                              }}
                            />
                          </Box>
                        </Box>
                      </LocalizationProvider>
                      <Box>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setBonusIsOpen(!bonusIsOpen)}
                        sx={{ mt: 2, mb: 2, mr: 2 }}
                      >
                       Начислить бонус
                      </Button>
                      <Button
                        color="warning"
                        variant="contained"
                        onClick={handleFilterQueryParams}
                        sx={{ mt: 2, mb: 2 }}
                      >
                        Поиск
                      </Button>
                      </Box>
                     
                    </Box>
                    <Divider />
                    <Box sx={{mb: 1}}>
                        <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                            {alert.txt}
                        </Alert>
                    </Box> {
                      bonusIsOpen &&                     <Container component="main" maxWidth="xs">
                      <Box
                        sx={{
                          marginTop: 8,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <Typography component="h1" variant="h5">
                          Начисление бонуса
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="cost"
                            label="Сколько"
                            name="cost"
                            autoComplete="cost"
                            autoFocus
                            value={cost}
                            onChange={(e) => setCost(e.target.value)}
                            error={errors.cost}
                            helperText={errors.cost && 'Введите бонус'}
                          />
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="description"
                            label="Описание"
                            name="description"
                            autoComplete="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            rows={2}
                          />
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                          >
                            Начислить
                          </Button>
                        </Box>
                      </Box>
                    </Container>
                    }

 
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: 80 }}>
                            Количество отработанных часов
                          </TableCell>
                          <TableCell>Сколько к оплате</TableCell>
                          <TableCell>Количество заявок</TableCell>
                          <TableCell>Не активен</TableCell>
                          <TableCell>Нецелевая</TableCell>
                          <TableCell>Отказ</TableCell>
                          <TableCell>Получен контакт</TableCell>
                          <TableCell>Успешно</TableCell>
                          <TableCell>Оплата</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover key={user.id}>
                          <TableCell sx={{ width: 80 }}>
                            {statistics?.hours}
                          </TableCell>

                          <TableCell>{statistics?.pay}</TableCell>

                          <TableCell>
                            {statistics?.applicationsCount || "---"}
                          </TableCell>

                          <TableCell>
                            {statistics?.notActiveApplicationsCount || "---"}
                          </TableCell>

                          <TableCell>
                            {statistics?.nonTargetApplicationsCount || "---"}
                          </TableCell>

                          <TableCell>
                            {statistics?.rejectedApplicationsCount || "---"}
                          </TableCell>

                          <TableCell>
                            {statistics?.contactApplicationsCount || "---"}
                          </TableCell>

                          <TableCell>
                            {statistics?.successApplicationsCount || "---"}
                          </TableCell>

                          <TableCell>
                            {statistics?.paidApplicationsCount || "---"}
                          </TableCell>
                   
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </PerfectScrollbar>
              </Card>
            </Box>
          </>
        </Container>
      </Box>

      <Box>
        <Box className="headerWrapper">
          <Box className="headerTitle">Отчет</Box>
        </Box>
        <Box sx={{ backgroundColor: "background.default", minHeight: "100%" }}>
          <Container maxWidth={false}>
            {isDataLoading ? (
              <UserListSkelet />
            ) : (
              <>
                <Box sx={{ pt: 3 }}>
                  <Card>
                    <PerfectScrollbar>
                      <Box sx={{ minWidth: 900 }}>
                        <Divider />
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ width: 80 }}>
                                Количество отработанных часов
                              </TableCell>
                              <TableCell>Сколько к оплате</TableCell>
                              <TableCell>Комментарий</TableCell>
                              <TableCell>Дата создания</TableCell>
                              <TableCell>Заявки</TableCell>
                              <TableCell>

</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {timeTracks?.map((timeTrack) => (
                              <TableRow hover key={timeTrack.id}>
                                <TableCell sx={{ width: 80 }}>
                                  {timeTrack.hours}
                                </TableCell>
                                <TableCell>{timeTrack.cost}</TableCell>
                                <TableCell>
                                  {timeTrack?.description
                                    ?.split("\n")
                                    .map((text) => {
                                      return <div>{text}</div>;
                                    }) || "---"}
                                </TableCell>
                                <TableCell>
                                  {new Date(
                                    timeTrack?.creationDate
                                  ).toLocaleString()}
                                </TableCell>
                                <TableCell>
                                  {timeTrack?.applications?.length
                                    ? timeTrack?.applications.map(
                                        (application) => {
                                          return (
                                            <div style={{marginBottom: 6}}>
                                              <Link
                                                to={`/app/applications/${application.id}`}
                                                style={{color: "#0D47A1"}}
                                              >
                                                Заявка №{application.id}
                                              </Link>
                                            </div>
                                          );
                                        }
                                      )
                                    : "---"}
                                </TableCell>

                                <TableCell sx={{width: 100}}>
                                                            <Button
                                                                color="error"
                                                                variant="contained"
                                                                onClick={() => deleteTrackingInfo(timeTrack.id)}
                                                            >
                                                                Удалить
                                                            </Button>
                                                        </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                labelRowsPerPage={
                                  <span>Кол-во строк на странице:</span>
                                }
                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                colSpan={7}
                                count={count}
                                rowsPerPage={limit}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeLimit}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </Box>
                    </PerfectScrollbar>
                  </Card>
                </Box>
              </>
            )}
          </Container>
        </Box>
      </Box>
<UserInfoBonusesTable />
    </>
  );
};

export default UserInfo;
