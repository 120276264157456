import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import { BallTriangle } from "react-loader-spinner";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from "chart.js";
import { Line } from "react-chartjs-2";
import { format, addDays } from "date-fns";
import WeekPicker from "./WeekPicker";
import ManagerDataTable from "./Table";
import moment from "moment";
import { useGet } from "../../API/request";
import { getArrayForChart } from "../../utils";

const Dashboard = () => {
  const [loaded, setLoaded] = useState(true);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [chartData, setChartData] = useState({ applications: [], leads: [], clients: [] });
  const [date, setDate] = useState({ start: null, end: null });
  const [labels, setLabels] = useState([]);

  const getU = useGet(); 

  const getDateLabels = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dateArray = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      dateArray.push(format(currentDate, "yyyy-MM-dd"));
      currentDate = addDays(currentDate, 1);
    }

    return dateArray;
  };

  const fetchChartData = async (start, end) => {
    setIsChartLoading(true);
    try {
      const resp = await getU(`admin/statistics/all/week?fromDate=${start}&toDate=${end}`);
      if (resp.status === 'success') {
        setChartData(resp.data.statistics);
        setLoaded(false)
      } else {
        console.error('Fetching chart data failed:', resp.data.message);
      }
    } catch (e) {
      console.error('Error fetching data:', e);
    }
    setIsChartLoading(false);
  };

  useEffect(() => {
    const today = new Date();
    const initialStart = format(today, "yyyy-MM-dd");
    const initialEnd = format(addDays(today, 6), "yyyy-MM-dd");
    setDate({ start: initialStart, end: initialEnd });
    setLabels(getDateLabels(initialStart, initialEnd));
  }, []);

  useEffect(() => {
    if (date.start && date.end) {
      fetchChartData(date.start, date.end);
      setLabels(getDateLabels(date.start, date.end));
    }
  }, [date]);

  useEffect(() => {
     fetchChartData(date.start, date.end);
  }, []);

  if (loaded) {
    return (
      <div className="loader">
        <BallTriangle height="100" width="100" color="grey" ariaLabel="loading" />
      </div>
    );
  }

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);



  let lineData = {
    labels: labels,
    datasets: [
      {
        label: 'Заявки',
        data: getArrayForChart(chartData.applications, labels),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
      {
        label: 'Лиды',
        data: getArrayForChart(chartData.leads, labels),
        fill: false,
        borderColor: '#E7D62A',
        tension: 0.1,
      },
      {
        label: 'Клиенты',
        data: getArrayForChart(chartData.clients, labels),
        fill: false,
        borderColor: '#36BB12',
        tension: 0.1,
      },
    ],
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Статистика</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Статистика</Box>
      </Box>
      <ManagerDataTable />
      {isChartLoading ? (
        <div className="loader">
          <BallTriangle height="100" width="100" color="grey" ariaLabel="loading" />
        </div>
      ) : (
        <>
          <Box className="headerWrapper">
            <WeekPicker date={date} setDate={setDate} />
            <Box className="headerTitle" sx={{ mb: 3 }}>
              Общая статистика с {date.start} по {date.end}
            </Box>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Container maxWidth={false}>
              <Line options={options} data={lineData} updateMode={"none"} />
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default Dashboard;
