import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

export function getWeekRange(date = new Date()) {
    const today = new Date(date);
    const dayOfWeek = today.getDay();
    const dayOfMonth = today.getDate();
    const currentDayOfWeek = (dayOfWeek === 0 ? 7 : dayOfWeek);

    const mondayDate = new Date(today);
    mondayDate.setDate(dayOfMonth - currentDayOfWeek + 1);

    const sundayDate = new Date(mondayDate);
    sundayDate.setDate(mondayDate.getDate() + 6);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}-${month}-${year}`;
    };

    const monday = formatDate(mondayDate);
    const sunday = formatDate(sundayDate);

    const week = [];
    for (let i = 0; i < 7; i++) {
        const currentDate = new Date(mondayDate);
        currentDate.setDate(mondayDate.getDate() + i);
        week.push(formatDate(currentDate));
    }



    
const mondayFormatted = formatToYYYYMMMDD(week[0])
const sundayFormatted = formatToYYYYMMMDD(week[6])
    return { mondayFormatted, sundayFormatted, monday, sunday, week };
}


export function formatToYYYYMMMDD (date) {
    const [day, month, year] = date.split('-');
    return `${year}-${month}-${day}`;
}
  
export function getMonthName (dateString) {
    const date = parseISO(dateString);
    return format(date, 'LLLL', { locale: ru });
  };

export function getArrayForChart(dataArr, weekArr) {
    console.log(dataArr, weekArr)
let result = []

weekArr.forEach(w => {
    const data = dataArr.find(d => {
        
        const dataDate = d.creationDate.split('T')[0];
  
        return w === dataDate;
      
    });

    if (data) {
        result.push(data.numOfApplications)
    } else {
        result.push(0)
    }
});
return result
}


export function getTodayDate() {
    const today = new Date(); 
    const year = today.getFullYear(); 
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const day = String(today.getDate()).padStart(2, '0'); 
    return `${year}-${month}-${day}`; 
  }