import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider,
    Checkbox,
    FormControlLabel,
    FormGroup
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/Confirm/index";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css'

const ApplicationList = () => {

    const getU = useGet();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);
    const [applications, setApplications] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
    const [checkboxValues, setCheckboxValues] = useState({
        notActive: true, 
        notTargeted: true,
        rejected: true, 
        contact: true, 
        success: true,
        paid: true

    });
   

    const [queryParams, setQueryParams] = useState({
        searchQuery: searchParams.get("searchQuery") || '',
    });

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        let params = {}

        if (queryParams.searchQuery !== '') {
            params.searchQuery = queryParams.searchQuery
        }

        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }


    const loadUsers = () => {
        setIsLoaded(true)

        const buildEndpoint = () => {
            const statusKeys = Object.keys(checkboxValues).filter(key => checkboxValues[key]);
           
            const statusParams = statusKeys.map(key => `status=${key}`).join('&');
            let endpoint = `/admin/applications?page=${page + 1}&limit=${limit}&${statusParams}`;
            return endpoint;
          };
        
       
          let endpoint = buildEndpoint();

        if (queryParams.searchQuery !== '') {
            endpoint += `&searchQuery=${searchParams.get("searchQuery")}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setApplications(resp.data.applications);
                    setCount(resp.data.totalCount || 0);
                }
            })
            .catch((err) => {
                setApplications([]);
                setCount(0);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    useEffect(() => {
        if (queryParams.searchQuery === '') {
            searchParams.delete("searchQuery")
            setSearchParams(searchParams);
        }
    }, [queryParams, checkboxValues])

    useEffect(() => {
        loadUsers();
    }, [page, limit, searchParams, checkboxValues]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Applications</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Заявки
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 1200}}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mx: 2,
                                            mb: 1
                                        }}>
                                            <Box 
                                            sx={{
                                                minWidth: '300px',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                mx: 2,
                                                mb: 1
                                            }}
                                            >       <TextField
                                                fullWidth
                                                label="Поиск"
                                                type='text'
                                                margin="normal"
                                                name="searchQuery"
                                                onChange={handleChangeQueryParams}
                                                value={queryParams.searchQuery}
                                                variant="outlined"
                                                style={{width: '90%', marginRight: 3}}
                                            />

                                            <Button
                                                color="warning"
                                                variant="contained"
                                                onClick={handleFilterQueryParams}
                                                sx={{mt: 2, mb: 1}}
                                            >
                                                Поиск
                                            </Button></Box>
                                     
                                            <Box>
      <FormGroup sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignContent: 'start',
        justifyContent: 'space-between',
        justifyItems: 'start' 
      }}>
          <FormControlLabel          
            onChange={() => { setCheckboxValues({...checkboxValues, notActive: !checkboxValues.notActive}) }} 
            control={<Checkbox  />} 
            checked={checkboxValues.notActive} 
            label="Не активные" />
          <FormControlLabel 
          onChange={() => { setCheckboxValues({...checkboxValues, notTargeted: !checkboxValues.notTargeted}) }} 
            value={checkboxValues.notTargeted} 
            checked={checkboxValues.notTargeted} 
            control={<Checkbox  />} 
            label="Нецелевая" />
          <FormControlLabel 
            onChange={() => { setCheckboxValues({...checkboxValues, rejected: !checkboxValues.rejected}) }} 
            value={checkboxValues.rejected} 
            checked={checkboxValues.rejected} 
            control={<Checkbox  />} 
            label="Отказ" />
      
          <FormControlLabel 
  onChange={() => { setCheckboxValues({...checkboxValues, contact: !checkboxValues.contact}) }} 
            value={checkboxValues.contact} 
            checked={checkboxValues.contact} 
            control={<Checkbox  />} 
            label="Получен контакт" />
          <FormControlLabel 
            onChange={() => { setCheckboxValues({...checkboxValues, success: !checkboxValues.success}) }} 
            value={checkboxValues.success} 
            checked={checkboxValues.success} 
            control={<Checkbox  />} 
            label="Успешно" />
          <FormControlLabel 
           onChange={() => { setCheckboxValues({...checkboxValues, paid: !checkboxValues.paid}) }} 
            value={checkboxValues.paid} 
            checked={checkboxValues.paid} 
            control={<Checkbox  />} 
            label="Оплата" />
       
      </FormGroup>
  
                                            
                                            </Box>
                                        </Box>
                                        <Divider/>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{width: 80}}>
                                                        № Заявки
                                                    </TableCell>
                                                    <TableCell>
                                                        Статус
                                                    </TableCell>
                                                    <TableCell>
                                                        Исполнитель
                                                    </TableCell>
                                                    <TableCell>
                                                        Сообщение
                                                    </TableCell>
                                                    <TableCell>
                                                        Почта
                                                    </TableCell>
                                                    <TableCell>
                                                        Телефон
                                                    </TableCell>
                                                    <TableCell>
                                                        Ссылка
                                                    </TableCell>

                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {applications?.map((item) => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell sx={{width: 80}}>
                                                            {item?.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.status || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.performer?.email || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.message || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.email || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.phone || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.link || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{display: 'flex'}}>
                                                                <Box sx={{display: 'flex'}}>
                                                                    <Link to={`/app/applications/${item.id}`}>
                                                                        <Button color="primary"
                                                                                variant="contained">
                                                                            Инфо.
                                                                        </Button>
                                                                    </Link>
                                                                    <Box sx={{ml: 2}}> 
                                                                        <Link
                                                                            to={ item?.role === "admin" || item.status !== 'Оплата' && `/app/applications/edit/${item.id}`}
                                                                        >
                                                                            <Button color="success"
                                                                                    variant="contained"
                                                                                    disabled={item?.role === "admin" || item.status === 'Оплата'}
                                                                            >
                                                                                Редакт.
                                                                            </Button>
                                                                        </Link>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        labelRowsPerPage={
                                                            <span>Кол-во строк на странице:</span>}
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default ApplicationList;
