// MonthPicker.js
import React, { useState } from 'react';
import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { format, startOfMonth, endOfMonth, isValid } from 'date-fns';
import { ru } from 'date-fns/locale';

const MonthPicker = ({ date, setDate }) => {
 

  const handleMonthChange = (date) => {
    if (isValid(date)) {
      setDate({
        start: format(startOfMonth(date), 'yyyy-MM-dd'),
      });
    }
  };

  const formatMonth = (date) => {
    return isValid(date) ? format(date, 'LLLL', { locale: ru }) : '';
  };

  return (
    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
      <DatePicker
        format="yyyy-MM"
        value={new Date(date.start)}
        onChange={handleMonthChange}
        cleanable={false}
        renderValue={(_, value) => formatMonth(value)}
        showMonth
        showMeridian={false}
        placement="bottomEnd"
      />
    </div>
  );
};

export default MonthPicker;
