import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useDelete, useGet, usePut} from '../../API/request';
import {BallTriangle} from "react-loader-spinner";
import {useConfirm} from "../../components/Confirm";

const PlatformList = () => {

    const getU = useGet();
    const putU = usePut();
    const deleteU = useDelete();
    const confirm = useConfirm();

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
    const [themes, setThemes] = useState([]);

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);


    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 3000);
    };

    const loadThemes = () => {
        setIsLoaded(true)


        let endpoint = `platforms?page=${page + 1}&limit=${limit}`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setThemes(resp.data.platforms);
                    setCount(resp.data.totalCount)
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                setThemes([]);
                setCount(0);
            })
            .finally(() =>{
                setIsLoaded(false)
            });

    };


    const onDeleteSetting = (id) => {
        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить данную платформу?',
            onConfirm: () => {
                deleteU(`admin/platforms/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadThemes();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', e.response.data.message.message)
                    });
            }
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        loadThemes();
    }, []);



    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Platforms</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Платформы
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>

                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>

                    <>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Box sx={{marginLeft: 2}}>
                                <Link to="/app/platforms/add">
                                    <Button color="primary" variant="contained">
                                        Добавить платформу
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 600}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Наименование
                                                    </TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {themes?.map((item) => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell>
                                                            {item?.name || "---"}
                                                        </TableCell>
                                                        <TableCell sx={{width: 100}}>
                                                            <Link to={`/app/platforms/edit/${item.id}`}>
                                                                <Button color="success" variant="contained">
                                                                    Редактирование
                                                                </Button>
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell sx={{width: 100}}>
                                                            <Button
                                                                color="error"
                                                                variant="contained"
                                                                onClick={() => onDeleteSetting(item.id)}
                                                            >
                                                                Удалить
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        labelRowsPerPage={
                                                            <span>Кол-во строк на странице:</span>}
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>


                </Container>
            </Box>
        </>
    );
};

export default PlatformList;
