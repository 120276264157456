import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    TextField
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {useGet, usePost, usePut} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";

const PlatformEdit = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const postU = usePost();
    const getU = useGet();
    const putU = usePut();

    const [values, setValues] = useState({
        name: '',
        key: '',

    });
    const [errors, setErrors] = useState({
        name: false,
        key: false,
    });

    const [isLoaded, setIsLoaded] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name.trim() === '') {
            validComplete = false;
            formErrors.name = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Ничего не изменилось');
            return;
        }
        if (validate()) {
            setSubmitDisabled(true);

            let data = {
                key: values.key,
                name: values.name
            }

            putU(`admin/platforms/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно обновили платформу');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message.message);

                })
                .finally(() => {
                    setSubmitDisabled(true);
                });
        }
    };


    useEffect(() => {
        setIsLoaded(true)
        getU(`platforms/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    const data = {
                        key: resp.data.platform.key,
                        name: resp.data.platform.name
                    }

                    setValues(data)
                }
            })
            .catch((err) =>{
                showAlert('error', err.response.data.message.message);
            })
            .finally(() =>{
                setIsLoaded(false)
            });

    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Theme edit</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Платформы
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Платформы</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>

            <Box sx={{ backgroundColor: 'background.default', minHeight: '85%', py: 3}}>
                <Container maxWidth={false} >
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование платформы"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default PlatformEdit;
