import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import {useGet, usePost, usePut} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/Avatar/style.css'

import {Link, useSearchParams} from 'react-router-dom';
import {
 Card, CardHeader, CardContent, Alert,
    Box,
    Container,
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Modal,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input
} from '@material-ui/core';
import { status } from 'nprogress';
import { Check } from '@mui/icons-material';


const CataloguesEdit = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut();
    const postU = usePost();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState({
        open: false,
        recordId: 0
    })
    const [values, setValues] = useState({
        name: '',

    });
    const [errors, setErrors] = useState({
        name: false,

    });

    const [catalogues, setCatalogues] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [hideRejected, setHideRejected] = useState(true);

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

   const getSpanForStatus = (status) => {
    if(status === 0) {
        return <span style={{
        }}>Новое</span>
    }
    if(status === 1) {
        return <span style={{
            color: "red"
        }}>Отклонена</span>
    }
    if(status === 2) {
        return <span style={{
            color: "blue"
        }}>В работе</span>
    }
    if(status === 3) {
        return <span style={{
            color: "green"
        }}>Выполнена</span>
    }
    if(status === 4) {
        return <span style={{
            color: "gray"
        }}>Устарела</span>
    }
   }

   console.log(hideRejected)

   const [selectedValue, setSelectedValue] = useState(0);

   const handleChangeSelect = (event) => {
     setSelectedValue(event.target.value);
   };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validateInfo = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name === '') {
            validComplete = false;
            formErrors.name = false;
            showAlert('error', "Поле Название не должно быть пустым")
        }

        setErrors(formErrors);
        return validComplete;
    };



    const submitInfo = async () => {
        if (nothingChanged) {
            showAlert('error', 'Нет изменений');
            return;
        }

        if (validateInfo()) {
            setSubmitDisabled(true);

            const data = {
                name: values.name,

            };

            putU(`catalog/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Данные успешно обновленны');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };


    const handleSaveStatus = async () => {
        const data = {
            status: selectedValue
        }
        await putU(`catalog/records/status/${modalIsOpen.recordId}`, data)
        .then((resp) => {
            if (resp.status === 'success') {
              getDataLists()
              setModalIsOpen({open: false})
            }

   
        })
        .catch((e) => {
            showAlert('error', e.response.data.message);
        })
        .finally(() => {

        }); 
    }

    const handleFileUpload = async () => {
    
        const formData = new FormData();
        formData.append('file', selectedFile)
        formData.append('catalog_id', id)
        setIsLoaded(true)
        await postU(`catalog/records/load`, formData)
        .then((resp) => {
            if (resp.status === 'success') {
              getDataLists()
              setIsLoaded(false)
            }

   
        })
        .catch((e) => {
            showAlert('error', e.response.data.message);
        })
        .finally(() => {

        }); 
    }
    
useEffect(() => {getInfoData()}, [])
    const getInfoData = async () => {
        setIsLoaded(true)
        await getU(`catalog/${id}`)
        .then((resp) => {
            if (resp.status === 'success') {
                setValues(resp.data)
            }

   
        })
        .catch((e) => {
            showAlert('error', e.response.data.message);
        })
        .finally(() => {
    })}

    const getDataLists = async () =>{
   
            await getU(`catalog/records/all?catalog_id=${id}&hideRejected=${hideRejected}&page=${page + 1}&limit=${limit}`)
                .then((resp) => {
                    if (resp.status === 'success') {
                        setCatalogues(resp.data.records);
                        setCount(resp.data.totalCount || 0);
                    }
                })
                .catch((err) => {
                    setCatalogues([]);
                    setCount(0);
                })
                .finally(() => {
                    setIsLoaded(false)
                });

    }

    useEffect(() => {
        getDataLists();
    }, [page, limit, hideRejected]);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Редактирование каталога</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Каталоги
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Каталоги</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>

            </Box>

            <Box sx={{backgroundColor: 'background.default', pt: 3}}>

                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title={`Редактирование заявки №${id}`}
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values?.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                </CardContent>
                                <Divider/>
                                <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                    {alert.txt}
                                </Alert>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitInfo}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>

                    <Card
      sx={{
        p: 4,
        boxShadow: 3,
        mx: 'auto',
        my: 4
      }}
    >
      <Typography variant="h4" component="h2">
        Загрузить файл
      </Typography>
      <Box sx={{ mt: 3 }}>
        <input
           accept=".xml,.json,.csv,.txt, .xls, .xlsx"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span">
          Выберите файл
          </Button>
        </label>
        {selectedFile && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            {selectedFile.name}
          </Typography>
        )}
      </Box>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleFileUpload()}
        sx={{ mt: 3 }}
      >
        Загрузить
      </Button>

      </Box>
    </Card>

                    <Card>
                        <Box sx={{display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                        <CardHeader
                                    title={`Записи для каталога №${id}`}
                                />
                                <FormControlLabel  label='Скрывать со статусом "Отклонено"' control={ <Checkbox onChange={() => setHideRejected(!hideRejected)} defaultChecked value={hideRejected}/>} />

                        </Box>
                      
                                <Divider/>
                                <Modal
      open={modalIsOpen.open}
      onClose={() => setModalIsOpen({open: false})}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
      
        <Card
          sx={{
            width: 400,
            p: 4,
            boxShadow: 3,
            position: "relative"
          }}
        >
                <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="text"
              color="primary"
              onClick={() => setModalIsOpen({open: false})}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              X
            </Button>
          </Box>

        
          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel id="select-label">Статус</InputLabel>
            <Select
              labelId="select-label"
              value={selectedValue}
              label="Статус"
              onChange={handleChangeSelect}
            >
           
           
              <MenuItem value={1}>Отклонена</MenuItem>
              <MenuItem value={2}>В работе</MenuItem>
              <MenuItem value={3}>Выполнена</MenuItem>
   
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveStatus()}
            sx={{ mt: 3 }}
          >
            Сохранить
          </Button>
        </Card>
      </Box>
    </Modal>

                                <CardContent sx={{position: 'relative'}}>
                                    
                                <Table>
                                        <TableHead>
    <TableRow>
        <TableCell>
            № Каталога
        </TableCell>
        <TableCell>
            Название
        </TableCell>
        <TableCell>
            Свежая
        </TableCell>
        <TableCell>
            Статус
        </TableCell>
        <TableCell>
         
        </TableCell>
    </TableRow>
</TableHead>
<TableBody>
    {catalogues?.map((item) => (
        <TableRow hover key={item.id} sx={{ maxWidth: '100%' }}>
            <TableCell sx={{ width: 80 }}>
                {item?.id}
            </TableCell>
            <TableCell sx={{ maxWidth: '200px' }}>
                {item?.name || '---'}
            </TableCell>
            <TableCell>
                {item?.freshness ? <span style={{
            color: 'green'
                }}>Да</span> : <span>Нет</span>}
            </TableCell>


            <TableCell>
                {getSpanForStatus(item?.status)}
            </TableCell>
            <TableCell sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex' }}>
                    <Link to={`/app/catalogues/${item.id}`}>
                        <Button color="primary" variant="contained">
                            Инфо.
                        </Button>
                    </Link>
                    <Box sx={{ ml: 2 }}>
                    
                            <Button
                                color="success"
                                variant="contained"
                                onClick={() => setModalIsOpen({open: true, recordId: item.id})}
                            >
                                Изменить статус
                            </Button>
                    
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
    ))}
</TableBody>

                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        labelRowsPerPage={
                                                            <span>Кол-во строк на странице:</span>}
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                </CardContent>
                                <Divider/>
                                <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                    {alert.txt}
                                </Alert>
                     
                            </Card>
                </Container>
            </Box>

        </>
    );
};

export default CataloguesEdit;
